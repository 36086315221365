//React
import React, {useEffect, useState} from 'react';
//React Routing
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
//Redux
import {useDispatch, useSelector} from 'react-redux';
import {isRefreshToken, updateData, updateWriteToken, userLogout} from './store/userSlice';
import {isLoading, loader} from './store/loaderSlice';
import './scss/main.scss';
import './scss/style.scss';
import './scss/vendor.css';


//Components
import Header from './components/Header/Header';
//Pages
import Sign from './pages/Sign';

import Main from './pages/Main';
import Footer from './components/Footer/Footer';
import Profle from './pages/Profle';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Loader from './components/Loader/Loader';
import axios from 'axios';
import Auth from './components/Authentication/Auth';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

import TagManager from 'react-gtm-module';
import SEO from './SEO';
import Forgot from "./pages/ForgotPassword";
import ForgotPassword from "./pages/ForgotPassword";
import RecoveryPassword from "./pages/RecoveryPassword";


const tagManagerArgs = {
    gtmId: 'G-4NREYLVWCR',
};

TagManager.initialize(tagManagerArgs);

function App() {

    const [pageTitle, setPageTitle] = useState(
        'AI Designer Pro · Plugin for Figma.'
    );
    const dispatch = useDispatch();

    const location = useLocation();
    const loader = useSelector((state) => state.loader.loader);
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.data);
    const [headerAnimetion, setHeaderAnimation] = useState(true);
    const searchParams = new URLSearchParams(location.search);
    const authParams = {
        writeToken: searchParams.get('writeToken'),
    };
    const headers = {
        'Refresh-Token': user.refreshToken,
        token: user.token,
    }

    function dateDifferenceInDays(date1, date2) {
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);

        const timeDifference = date2.getTime() - date1.getTime();

        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        return daysDifference;
    }

    useEffect(() => {
            const currentDate = new Date();

            const getLoginData = JSON.parse(localStorage.getItem('userLoginDate'))
            const loginDate = new Date(getLoginData)


            if (getLoginData === null) {
                localStorage.setItem('userLoginDate', JSON.stringify(currentDate))
            } else {
                console.log(dateDifferenceInDays(currentDate, loginDate))
                if (dateDifferenceInDays(currentDate, loginDate) === 0) {

                }
                if (dateDifferenceInDays(currentDate, loginDate) >= 2 || dateDifferenceInDays(currentDate, loginDate) <= -2) {
                   dispatch(userLogout())
                } else {


                    axios.post(
                        `https://figmaai-backend-c99f609c114b.herokuapp.com/refresh-token`,
                        {},
                        {headers},
                    )
                        .then(res => {
                            console.log(res)
                            dispatch(
                                isRefreshToken({
                                    token: res.data.accessToken,
                                    refreshToken: res.data.refreshToken,
                                })
                            );
                            localStorage.setItem('userLoginDate', JSON.stringify(currentDate))
                        })
                        .catch(err => console.error(err))

                }
            }


        }

        ,
        []
    )

    useEffect(() => {
        if (authParams.writeToken !== null) {
            console.log(authParams.writeToken);
            localStorage.setItem('writeToken', JSON.stringify(authParams.writeToken));
            dispatch(updateWriteToken({writeToken: authParams.writeToken}));

            if (location.hash === '#pricing') {
                if (!user.loginned) {
                    navigate(`/sign?writeToken=${authParams.writeToken}`);
                }
            }
        }
    }, []);

    useEffect(() => {
        const broadcastChannel = new BroadcastChannel('logoutChannel');
        broadcastChannel.addEventListener('message', event => {
            if (event.data === 'logout') {
                // Dispatch the logout action
                dispatch(userLogout());

                // Clean local storage or perform other actions
                localStorage.clear();
            }
        });

        return () => {
            broadcastChannel.close();
        };
    }, []);


    useEffect(() => {
        const getUserData = localStorage.getItem('userData');
        const useData = JSON.parse(getUserData);

        if (useData !== null) {
            dispatch(updateData(useData));
        }
    }, []);

    useEffect(() => {
        if (location.pathname === '/') {
            setHeaderAnimation(true);
        } else {
            setHeaderAnimation(false);
        }
    }, [location]);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(isLoading({loader: true}));

        setTimeout(() => {
            dispatch(isLoading({loader: false}));
        }, 1000);
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === '/') {
            setPageTitle('AI Designer Pro · Plugin for Figma.');
        } else if (location.pathname === '/profile') {
            setPageTitle('Account');
        } else if (location.pathname === '/auth') {
            setPageTitle('Connect your account');
        } else if (location.pathname === '/terms') {
            setPageTitle('Terms of use');
        } else if (location.pathname === '/privacy') {
            setPageTitle('Privacy');
        } else if (location.pathname === '/sign') {
            setPageTitle('Sign in');
        }
    }, [location]);
    const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    }

    let scrollTimer; // A timer to detect scrolling stop

    window.addEventListener('scroll', () => {
      clearTimeout(scrollTimer);
      handleScroll();
      // Set a timeout to detect when scrolling stops
      scrollTimer = setTimeout(() => {
        setScrolling(false);
      }, 200); // Adjust the timeout duration as needed
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const styleElement = document.createElement('style');
    const thumbColor = scrolling ? '#959597' : 'transparent';
    styleElement.innerHTML = `body::-webkit-scrollbar-thumb { background-color: ${thumbColor}; }`;
    document.head.appendChild(styleElement);
  }, [scrolling]);
    return (
        <>
            {/* <SEO
        title={pageTitle}
        type={'website'}
        standard_image={standard_image}
        social_image={social_image}
      /> */}
            <Header animation={headerAnimetion}/>
            {loader ? <Loader/> : null}

            <Routes>
                <Route path='/' element={<Main/>}/>
                <Route path='/sign' element={<Sign/>}/>
                <Route path='/forgot' element={<ForgotPassword/>}/>
                <Route path='/recovery' element={<RecoveryPassword/>}/>

                <Route
                    path='/profile'
                    element={user.loginned ? <Profle/> : <Main/>}
                />

                <Route
                    path='/auth'
                    element={user.writeToken !== null ? <Auth/> : <Main/>}
                />

                <Route path='/terms' element={<Terms/>}/>
                <Route path='/privacy' element={<Privacy/>}/>
                <Route path='*' element={<Main/>}/>
            </Routes>
            {location.pathname !== '/profile' && <Footer animation={headerAnimetion}/>}

        </>
    );
}

export default App;

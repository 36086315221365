//Import React
import React, {useEffect, useState} from 'react';

//Import Axios
import axios from 'axios';

//Import Route
import {useNavigate, useLocation} from 'react-router-dom';

//Import React-Hook-Form

import {useForm, FormProvider} from "react-hook-form"


//Import Redux
import {useSelector, useDispatch} from 'react-redux';
import {updateData} from '../../store/userSlice';

//Import components
import Input from '../Input/Input';

//Import Images

import { isSign } from '../../store/userAuthSlice';

import InputPassword from "../Input/InputPassword";

const SignUp = () => {
    const navigate = useNavigate();
    const [writeToken, setWhiteToken] = useState(null);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.data);



    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const authParams = {
        state: searchParams.get('state'),
        code: searchParams.get('code'),
        scope: searchParams.get('scope'),
        authuser: searchParams.get('authuser'),
        prompt: searchParams.get('prompt'),
        writeToken: searchParams.get('writeToken'),
    };


    const registration = (email,password) => {

            axios
                .post('https://figmaai-backend-c99f609c114b.herokuapp.com/register', {
                    email: email,
                    enabled: true,
                    googleId: '',
                    password: password,
                    verified: true,
                })
                .then((res) => {
                    if (res.status === 200) {
                        login(email, password);
                    } else {
                    }
                })
                .catch((err) => {
                    methods.setError("email", {
                        type: "manual",
                        message: `Email already reserved`,
                    })

                });
        }


    useEffect(() => {
        const writeTokenLS = localStorage.getItem('writeToken');
        const writeTokenParse = JSON.parse(writeTokenLS);
        setWhiteToken(writeTokenParse);
    }, []);

    const login = (email, password) => {

        axios
            .post('https://figmaai-backend-c99f609c114b.herokuapp.com/login', {
                login: email,
                password: password,
                writeToken: writeToken !== null ? writeToken : '',
            })
            .then((res) => {
                console.log('login', res);
                if (res.status === 200) {
                    dispatch(isSign())
                    dispatch(
                        updateData({
                            token: res.data.accessToken,
                            refreshToken: res.data.refreshToken,
                            email: email,
                            loginned: true,
                        })
                    );

                    localStorage.setItem('userData', JSON.stringify(userData));
                    navigate('/profile');
                }
            })
            .catch((error) => {
                if (error.response.data.message === "Invalid password!") {
                    methods.setError("password", {
                        type: "manual",
                        message: error.response.data.message,
                    })
                } else {
                    methods.setError("email", {
                        type: "manual",
                        message: error.response.data.message,
                    })
                }

            })

    }


    const userSubscriptNameJSON = localStorage.getItem('userSubscriptName');
    const userSubscriptNName = JSON.parse(userSubscriptNameJSON);

    useEffect(() => {
        if (userData.loginned === true) {
            if (userSubscriptNName) {
                // getPaymentUrl(userSubscriptNName);
            } else {
                navigate('/profile');
            }
        }
    }, [userData]);

    const methods = useForm()

    const onSubmit = (data) => {
        if(data.password === data.confirmPassword) {
            registration(data.email, data.password)
        } else {
            methods.setError("password", {
                type: "manual",
                message: 'Passwords do not match',
            })
            methods.setError("confirmPassword", {
                type: "manual",
                message: 'Passwords do not match',
            })
        }

    }
    const onError = (errors, e) => console.log(errors, e);


    return (
        <FormProvider {...methods}>
            <form className='sign__form' onSubmit={methods.handleSubmit(onSubmit, onError)}>
                <div className="sign__form-inputs">
                    <Input
                        object_key={'email'}
                        name={'email'}
                        placeholder={'Email address'}
                        required
                    />
                    <InputPassword
                        object_key={'password'}
                        name={'password'}
                        placeholder={'Password'}
                        required
                    />
                    <InputPassword
                        object_key={'confirmPassword'}
                        name={'confirmPassword'}
                        placeholder={'Confirm Password'}
                        required
                    />
                </div>

                <button
                    className='btn-main btn-main--large btn-main--purple'
                    type='submit'>
                    Sign up
                </button>

            </form>


        </FormProvider>
    );
};

export default SignUp;
